<template>
 <div class="bg-white">
    <div class="relative pb-10">
      <div>
        <div class="relative">
          <div class="absolute inset-0">
            <!-- <video id="bg-vid" autoplay loop muted playsinline>
              <source src="/video/home-bg.mp4" type="video/mp4" />
            </video> -->
            <img class="w-full h-full object-cover" src="https://pslive.blob.core.windows.net/images/event/81be38c0-b197-11ef-8575-bf85d8bfee0f-event.jpg" alt="" />
            <div class="absolute inset-0" aria-hidden="true" style="background-image: linear-gradient(to left bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8))" />
          </div>
          <div class="relative max-w-7xl mx-auto md:p-6">
            <div class="max-w-3xl mx-auto">
              <div>
                <img src="https://pslive.blob.core.windows.net/images/event/81be38c0-b197-11ef-8575-bf85d8bfee0f-event.jpg" alt="" class="md:rounded-xl">
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8" id="info-panel">
        <!-- <img class="mb-20 rounded-xl" src="/img/prayer-storm-conference-2025-1920x1080.jpg" /> -->

        <h1 class="mt-10 lg:mt-15 font-display text-4xl font-bold tracking-tighter text-gray-900 sm:text-6xl">
          Join a Nazarite Hub</h1>
        <div class="mt-6 space-y-6 font-display text-2xl tracking-tight text-gray-600">
          <p>Remember, anyone can create a hub so Prayer Storm cannot vet hub leaders. Hubs are designed for existing communities, so we recommend only joining a hub with someone who you already know and never go to an unknown person's house alone.</p>
        </div>
      </div>

      <section v-if="!isLoggedIn" aria-labelledby="schedule-title" class="py-10">
        <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div  class="space-y-6 font-display text-lg tracking-tight text-gray-600 italic">
            <div class="relative mx-auto max-w-xl">
              <p>Please login to join a Nazarite Hub.</p>
              <div>
                <button @click.prevent="loginForHubs" class="inline-flex justify-center rounded-xl bg-blue p-2 md:p-4 text-sm md:text-base font-semibold text-white hover:bg-blue-dark focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-500">Login or Sign Up</button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section v-else aria-labelledby="schedule-title" class="py-4">
        <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div class="relative mx-auto max-w-xl">
            <div class="">
              <div class="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
                <div class="sm:col-span-2">
                  <label for="hubCode" class="block text-xl font-bold">Enter Nazarite Hub Code</label>
                  <p class="mt-1 text-gray-500 italic">This should be provided by the hub's host. Alternatively they may provide you with a direct link to the hub.</p>
                  <div class="mt-2">
                    <input v-model="formState.hubCode" type="text" name="hubCode" id="hubCode" class="block w-full rounded-md border-gray-300 py-3 px-4 shadow-sm focus:border-blue focus:ring-blue" />
                  </div>
                </div>
              </div>
              <div class="pt-10 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
                <div class="sm:col-span-2">
                  <ErrorMessage :formError="formState.error" class="mb-6"></ErrorMessage>
                  <SuccessMessage :successMessage="formState.result" class="mb-6"></SuccessMessage>
                  <button type="button" @click.prevent="submitForm" :disabled="formState.executing || formState.result !== ''" class="disabled:opacity-50 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-blue px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-blue focus:ring-offset-2">Search</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { mapGetters } from 'vuex'
import responseHelper from '@/helpers/response.helper'
import ErrorMessage from '@/components/ErrorMessage.vue'
import SuccessMessage from '@/components/SuccessMessage.vue'
import { ensureLoggedInOrRedirect } from '@/lib/util'
import { useHubs } from '@/composables/useHubs'

export default defineComponent({
  components: {
    ErrorMessage,
    SuccessMessage
  },

  setup () {
    const route = useRoute()
    const router = useRouter()

    const { postHubSearchByCode } = useHubs()

    const formState = reactive({
      hubCode: '',
      executing: false,
      error: responseHelper.noError,
      result: ''
    })

    const loginForHubs = () => {
      ensureLoggedInOrRedirect(router, route)
    }

    const submitForm = async () => {
      postHubSearchByCode(formState)
        .then((result) => {
          // implement
          if (result && result.hubId) {
            router.push('/nazarite-hubs/join/' + result.hubId)
          }
        })
        .catch(() => { })
    }

    return {
      loginForHubs,
      formState,
      submitForm
    }
  },
  computed: {
    ...mapGetters([
      'isLoggedIn'
    ])
  }
})
</script>
