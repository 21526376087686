// import { reactive } from 'vue'
import axios from 'axios'
// import store from '../store'
import responseHelper from '../helpers/response.helper'
// import { DateTime } from 'luxon'

export function useHubs () {
  const postHubCreate = async (formState) => {
    // validate the type
    if (formState.hubType !== 'online' && formState.hubType !== 'location') {
      formState.error = responseHelper.createError('Validation Failed', ['Hub Type is required'])
      return
    }
    const online = formState.hubType === 'online'

    if (!formState.executing) {
      formState.executing = true
      formState.error = responseHelper.noError
      try {
        const response = await axios.post('hubs', {
          firstName: formState.firstName,
          lastName: formState.lastName,
          tel: formState.tel,
          hubType: formState.hubType,
          title: formState.title,
          description: formState.description,
          country: formState.country,
          addressOne: online ? undefined : formState.addressOne,
          addressTwo: online ? undefined : formState.addressTwo,
          townCity: online ? undefined : formState.townCity,
          county: online ? undefined : formState.county,
          postCode: online ? undefined : formState.postCode,
          online
        })
        return response.data.result
      } catch (err) {
        formState.error = responseHelper.getMessageV2(err)
      } finally {
        formState.executing = false
      }
    }
  }

  const postHubSearchByCode = async (formState) => {
    if (!formState.executing) {
      formState.executing = true
      formState.result = ''
      formState.error = responseHelper.noError
      try {
        const response = await axios.post('hubs/search/code', {
          code: formState.hubCode
        })
        formState.result = response.data.result
      } catch (err) {
        formState.result = ''
        formState.error = responseHelper.getMessageV2(err)
      } finally {
        formState.executing = false
      }

      return formState.result
    }
  }

  const postHubJoinByCode = async (formState) => {
    if (!formState.executing) {
      formState.executing = true
      formState.errorJoin = responseHelper.noError
      try {
        const response = await axios.post('hubs/join/code', {
          code: formState.hubCode,
          firstName: formState.firstName,
          lastName: formState.lastName,
          tel: formState.tel
        })
        return response.data.result
      } catch (err) {
        formState.errorJoin = responseHelper.getMessageV2(err)
        throw err
      } finally {
        formState.executing = false
      }
    }
  }

  return {
    postHubCreate,
    postHubSearchByCode,
    postHubJoinByCode
  }
}
