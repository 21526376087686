<template>
  <div class="bg-gray-100">
    <div class="mx-auto py-12 lg:py-24 px-4 sm:px-6 lg:max-w-7xl lg:px-8">
      <h2 class="sr-only">Checkout</h2>
      <div v-if="viewState.loading" >
          <p>Loading...</p>
      </div>

      <ul v-if="viewState.event" role="list" class="">
        <li class="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div class="" v-if="viewState.event.imageUrl">
            <img :src="viewState.event.imageUrl" alt="Event Image" class="aspect-video w rounded-lg object-cover object-center" />
          </div>
          <div>
            <h1 class="text-3xl font-bold text-gray-900">
              {{ viewState.event.title }}
            </h1>

            <h2 class="text-lg font-bold text-gray-600 mt-4">
              Select an option:
            </h2>

            <ul role="list" class="divide-y divide-gray-100 overflow-hidden bg-white shadow-sm ring-1 ring-gray-200 sm:rounded-xl mt-2">
              <li v-for="option in viewState.options" :key="option.id" class="relative flex justify-between gap-x-6 px-4 py-5 hover:bg-gray-200 sm:px-6">
                <div class="flex min-w-0 gap-x-4">
                  <div class="min-w-0 flex-auto">
                    <p class="text-sm font-bold leading-6 text-gray-900">
                      <a @click.prevent="navigateOption(option)" href="#">
                        <span class="absolute inset-x-0 -top-px bottom-0" />
                        {{ option.title }}
                      </a>
                    </p>
                    <p class="mt-1 flex text-xs leading-5 text-gray-500">
                      {{ option.description }}
                    </p>
                  </div>
                </div>
                <div class="flex shrink-0 items-center gap-x-4">
                  <ChevronRightIcon class="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                </div>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <!-- <SectionSupport></SectionSupport> -->
</template>

<script>
import { mapGetters, useStore } from 'vuex'
import { defineComponent, onMounted, reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { DateTime } from 'luxon'
import { ChevronRightIcon } from '@heroicons/vue/20/solid'
// import SectionSupport from '@/components/SectionSupport.vue'

export default defineComponent({
  components: {
    ChevronRightIcon
    // SectionSupport
  },
  setup () {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const viewState = reactive({
      loading: true,
      loadError: undefined,
      event: undefined,
      options: undefined
    })

    onMounted(async () => {
      try {
        if (route.params.dataType && route.params.dataType.toLowerCase() === 'event') {
          const result = await store.dispatch('checkout/getEventOptions', {
            eventId: route.params.recordId
          })
          viewState.event = result.event
          if (viewState.event && viewState.event.imageUrl) {
            viewState.event.imageUrl = process.env.VUE_APP_BLOB_CONTAINER + '/images/' + viewState.event.imageUrl
          }
          viewState.options = result.options

          viewState.loading = false
        }
      } catch (e) {
        viewState.ticketInfo = undefined
        viewState.loadError = e

        store.commit('global/setErrorModal', {
          title: 'Error Loading',
          description: 'Please refresh the page and try again. If the problem persists, please contact support.',
          open: true
        })
      }
    })

    const navigateOption = (option) => {
      if (option.url) {
        if (option.url.startsWith('http')) {
          window.open(option.url, '_blank')
        } else {
          router.push(option.url)
        }
      } else {
        router.push(route.fullPath + '/' + option.id)
      }
    }

    return {
      viewState,
      DateTime,
      navigateOption
    }
  },
  computed: {
    ...mapGetters([
      'isLoggedIn'
    ])
  }
})
</script>
