<template>
 <div class="bg-white">
    <div class="relative pb-10">
      <div>
        <div class="relative">
          <div class="absolute inset-0">
            <!-- <video id="bg-vid" autoplay loop muted playsinline>
              <source src="/video/home-bg.mp4" type="video/mp4" />
            </video> -->
            <img class="w-full h-full object-cover" src="https://pslive.blob.core.windows.net/images/event/81be38c0-b197-11ef-8575-bf85d8bfee0f-event.jpg" alt="" />
            <div class="absolute inset-0" aria-hidden="true" style="background-image: linear-gradient(to left bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8))" />
          </div>
          <div class="relative max-w-7xl mx-auto md:p-6">
            <div class="max-w-3xl mx-auto">
              <div>
                <img src="https://pslive.blob.core.windows.net/images/event/81be38c0-b197-11ef-8575-bf85d8bfee0f-event.jpg" alt="" class="md:rounded-xl">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="relative z-50 flex-none border-b py-2">
        <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 flex flex-wrap items-center justify-center lg:flex-nowrap">
          <div class="flex space-x-2 md:space-x-4">
            <router-link to="/event/117/nazarite-school" class="inline-flex justify-center rounded-xl bg-blue p-2 md:p-4 text-sm md:text-base font-semibold text-white hover:bg-blue-dark focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-500" href="#">
              <span>Attend Manchester Nazarite School</span>
            </router-link>
          </div>
        </div>
      </div>

      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8" id="info-panel">
        <!-- <img class="mb-20 rounded-xl" src="/img/prayer-storm-conference-2025-1920x1080.jpg" /> -->

        <h1 class="mt-10 lg:mt-15 font-display text-4xl font-bold tracking-tighter text-gray-900 sm:text-6xl">
          8 Simple Steps to Host a Hub</h1>
        <div class="mt-6 space-y-6 font-display text-xl tracking-tight text-gray-600">
          <ol class="list-decimal list-inside space-y-2">
            <li><strong>Register</strong> a Nazarite hub below, then you'll receive your unique hub link via email. </li>
            <li>If you're not in church leadership, <strong>inform</strong> a pastor or prayer co-ordinator that you're hosting a hub.  </li>
            <li><strong>Share your hub link</strong> with your friends to register. Post something online and ask some others to help you host and invite their friends. Why not watch and share some of these Nazarite videos so everyone knows what to expect. </li>
            <li>Create a <strong>WhatsApp group</strong> to communicate with your hub. You'll receive delegates numbers when they register.</li>
            <li>Book a <strong>meeting place</strong> (a prayer room, church office or your living room). If it's an online group, Google Meet is a free platform and easy to share with others. Ensure there is bread and juice for communion. </li>
            <li>Do a <strong>technical check</strong> before the event, ensure the internet, sound, TV or laptop all work. If it's an online group, ensure you know how to share your screen (so you can share the live stream) and create break out rooms. </li>
            <li>On the <strong>night</strong>: Welcome people, play the live-stream link we'll give you, and help organise accountability groups (see FAQs below). It's vital to encourage participation, especially when it gets late, and people are tempted to sit down to 'pray'.</li>
            <li>Ensure everyone can get <strong>home safely</strong> and pray for your fellow Nazarites during the week. </li>
          </ol>
          <!-- Unfortunately, we are unable to promote or advertise your Nazarite Hub, but we will provide you with a registration link to your Nazarite Hub that you can use to promote your Nazarite Hub and allow others to join.  -->
        </div>

      </div>

      <section aria-labelledby="schedule-title" class="py-4">
        <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">

          <div v-if="!isLoggedIn" class="space-y-6 font-display text-lg tracking-tight text-gray-600 italic">
            <p>Please login to register and host a Nazarite Hub.</p>

            <div>
              <button @click.prevent="loginForHubs" class="inline-flex justify-center rounded-xl bg-blue p-2 md:p-4 text-sm md:text-base font-semibold text-white hover:bg-blue-dark focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-500">Login or Sign Up</button>
            </div>
          </div>

          <div v-else class="overflow-hidden bg-white py-8 px-4 sm:px-6 lg:px-8">
            <div class="relative mx-auto max-w-xl">
              <svg class="absolute left-full translate-x-1/2 transform" width="404" height="404" fill="none" viewBox="0 0 404 404" aria-hidden="true">
                <defs>
                  <pattern id="85737c0e-0916-41d7-917f-596dc7edfa27" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                    <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width="404" height="404" fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)" />
              </svg>
              <svg class="absolute right-full bottom-0 -translate-x-1/2 transform" width="404" height="404" fill="none" viewBox="0 0 404 404" aria-hidden="true">
                <defs>
                  <pattern id="85737c0e-0916-41d7-917f-596dc7edfa27" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                    <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width="404" height="404" fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)" />
              </svg>
              <div class="">
                <div class="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
                  <div class="text-center sm:col-span-2">
                    <h2 class="text-xl font-bold tracking-tight text-gray-900 sm:text-2xl">Your Details</h2>
                    <p>So people can contact you as the host.</p>
                  </div>
                  <div>
                    <label for="first-name" class="block text-sm font-medium text-gray-700">First Name</label>
                    <div class="mt-1">
                      <input v-model="formState.firstName" type="text" name="first-name" id="first-name" autocomplete="given-name" class="block w-full rounded-md border-gray-300 py-3 px-4 shadow-sm focus:border-blue focus:ring-blue" />
                    </div>
                  </div>
                  <div>
                    <label for="last-name" class="block text-sm font-medium text-gray-700">Last Name</label>
                    <div class="mt-1">
                      <input v-model="formState.lastName" type="text" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-gray-300 py-3 px-4 shadow-sm focus:border-blue focus:ring-blue" />
                    </div>
                  </div>
                  <div class="sm:col-span-2">
                    <label for="email" class="block text-sm font-medium text-gray-700">Email</label>
                    <div class="mt-1">
                      <input v-model="formState.email" disableds id="email" name="email" type="email" autocomplete="email" class="block w-full rounded-md border-gray-200 text-gray-500 py-3 px-4 shadow-sm focus:border-blue focus:ring-blue bg-gray-50 shadow-none" />
                    </div>
                  </div>
                  <div class="sm:col-span-2">
                    <label for="tel" class="block text-sm font-medium text-gray-700">Telephone</label>
                    <div class="mt-1">
                      <input v-model="formState.tel" id="tel" name="tel" type="text" autocomplete="tel" class="block w-full rounded-md border-gray-300 py-3 px-4 shadow-sm focus:border-blue focus:ring-blue" />
                    </div>
                  </div>
                  <div class="pt-4 text-center sm:col-span-2">
                    <h2 class="text-xl font-bold tracking-tight text-gray-900 sm:text-2xl">Hub Details</h2>
                    <p>So people know which hub is yours.</p>
                  </div>

                  <div class="sm:col-span-2">
                    <label for="hubType" class="block text-sm font-medium text-gray-700">Hub Type</label>
                    <p class="italic text-sm text-gray-600">Location hubs meet together at a physical location. Online hubs meet via online video conferencing. Both types are facilitated by the host registering.</p>
                    <div class="mt-1">
                      <select v-model="formState.hubType" id="hubType" name="hubType" class="mt-1 block w-full border border-gray-300 rounded-md py-3 px-4 focus:outline-none focus:ring-blue focus:border-blue">
                        <option value="">Please specify...</option>
                        <option value="online">Online Hub</option>
                        <option value="location">Location Hub (e.g. Church or House Group)</option>
                      </select>
                    </div>
                  </div>
                  <div class="sm:col-span-2">
                    <label for="title" class="block text-sm font-medium text-gray-700">Name</label>
                    <div class="mt-1">
                      <p class="italic text-sm text-gray-600">e.g. Hope Church Newcastle Nazarite Hub</p>
                      <input v-model="formState.title" id="title" name="title" type="text" class="block w-full rounded-md border-gray-300 py-3 px-4 shadow-sm focus:border-blue focus:ring-blue" />
                    </div>
                  </div>
                  <div class="sm:col-span-2">
                    <label for="description" class="block text-sm font-medium text-gray-700">Description</label>
                    <div class="mt-1">
                      <p class="italic text-sm text-gray-600">Provide a brief description for those signing up. E.g. For the Spartans mens group, which will be hosted online.</p>
                      <textarea v-model="formState.description" id="description" name="description" type="text" class="block w-full rounded-md border-gray-300 py-3 px-4 shadow-sm focus:border-blue focus:ring-blue" />
                    </div>
                  </div>
                  <div class="sm:col-span-2">
                    <label for="country" class="block text-sm font-medium text-gray-700">Country</label>
                    <div class="mt-1">
                      <select v-model="formState.country" id="country" name="country" class="mt-1 block w-full border border-gray-300 rounded-md py-3 px-4 focus:outline-none focus:ring-blue focus:border-blue">
                        <option value="">Please specify...</option>
                        <option value="">-------</option>
                        <option value="GB">United Kingdom (GB and NI)</option>
                        <option value="US">United States of America</option>
                        <option value="NL">Netherlands</option>
                        <option value="">-------</option>
                        <option value="AF">Afghanistan</option>
                        <option value="AL">Albania</option>
                        <option value="DZ">Algeria</option>
                        <option value="AS">American Samoa</option>
                        <option value="AD">Andorra</option>
                        <option value="AO">Angola</option>
                        <option value="AI">Anguilla</option>
                        <option value="AQ">Antarctica</option>
                        <option value="AG">Antigua and Barbuda</option>
                        <option value="AR">Argentina</option>
                        <option value="AM">Armenia</option>
                        <option value="AW">Aruba</option>
                        <option value="AU">Australia</option>
                        <option value="AT">Austria</option>
                        <option value="AZ">Azerbaijan</option>
                        <option value="BS">Bahamas</option>
                        <option value="BH">Bahrain</option>
                        <option value="BD">Bangladesh</option>
                        <option value="BB">Barbados</option>
                        <option value="BY">Belarus</option>
                        <option value="BE">Belgium</option>
                        <option value="BZ">Belize</option>
                        <option value="BJ">Benin</option>
                        <option value="BM">Bermuda</option>
                        <option value="BT">Bhutan</option>
                        <option value="BO">Bolivia (Plurinational State of)</option>
                        <option value="BQ">Bonaire, Saint Eustatius and Saba</option>
                        <option value="BA">Bosnia and Herzegovina</option>
                        <option value="BW">Botswana</option>
                        <option value="BV">Bouvet Island</option>
                        <option value="BR">Brazil</option>
                        <option value="IO">British Indian Ocean Territory</option>
                        <option value="BN">Brunei Darussalam</option>
                        <option value="BG">Bulgaria</option>
                        <option value="BF">Burkina Faso</option>
                        <option value="BI">Burundi</option>
                        <option value="CV">Cabo Verde</option>
                        <option value="KH">Cambodia</option>
                        <option value="CM">Cameroon</option>
                        <option value="CA">Canada</option>
                        <option value="KY">Cayman Islands</option>
                        <option value="CF">Central African Republic</option>
                        <option value="TD">Chad</option>
                        <option value="CL">Chile</option>
                        <option value="CN">China</option>
                        <option value="CX">Christmas Island</option>
                        <option value="CC">Cocos (Keeling) Islands</option>
                        <option value="CO">Colombia</option>
                        <option value="KM">Comoros</option>
                        <option value="CG">Congo</option>
                        <option value="CD">Congo (Democratic Republic of the)</option>
                        <option value="CK">Cook Islands</option>
                        <option value="CR">Costa Rica</option>
                        <option value="HR">Croatia</option>
                        <option value="CU">Cuba</option>
                        <option value="CW">Curaçao</option>
                        <option value="CY">Cyprus</option>
                        <option value="CZ">Czech Republic</option>
                        <option value="CI">Côte d'Ivoire</option>
                        <option value="DK">Denmark</option>
                        <option value="DJ">Djibouti</option>
                        <option value="DM">Dominica</option>
                        <option value="DO">Dominican Republic</option>
                        <option value="EC">Ecuador</option>
                        <option value="EG">Egypt</option>
                        <option value="SV">El Salvador</option>
                        <option value="GQ">Equatorial Guinea</option>
                        <option value="ER">Eritrea</option>
                        <option value="EE">Estonia</option>
                        <option value="SZ">Eswatini (Kingdom of)</option>
                        <option value="ET">Ethiopia</option>
                        <option value="FK">Falkland Islands (Malvinas)</option>
                        <option value="FO">Faroe Islands</option>
                        <option value="FJ">Fiji</option>
                        <option value="FI">Finland</option>
                        <option value="FR">France</option>
                        <option value="GF">French Guiana</option>
                        <option value="PF">French Polynesia</option>
                        <option value="TF">French Southern Territories</option>
                        <option value="GA">Gabon</option>
                        <option value="GM">Gambia</option>
                        <option value="GE">Georgia</option>
                        <option value="DE">Germany</option>
                        <option value="GH">Ghana</option>
                        <option value="GI">Gibraltar</option>
                        <option value="GR">Greece</option>
                        <option value="GL">Greenland</option>
                        <option value="GD">Grenada</option>
                        <option value="GP">Guadeloupe</option>
                        <option value="GU">Guam</option>
                        <option value="GT">Guatemala</option>
                        <option value="GG">Guernsey</option>
                        <option value="GN">Guinea</option>
                        <option value="GW">Guinea-Bissau</option>
                        <option value="GY">Guyana</option>
                        <option value="HT">Haiti</option>
                        <option value="HM">Heard Island and McDonald Islands</option>
                        <option value="HN">Honduras</option>
                        <option value="HK">Hong Kong</option>
                        <option value="HU">Hungary</option>
                        <option value="IS">Iceland</option>
                        <option value="IN">India</option>
                        <option value="ID">Indonesia</option>
                        <option value="IR">Iran (Islamic Republic of)</option>
                        <option value="IQ">Iraq</option>
                        <option value="IE">Ireland (Republic of)</option>
                        <option value="IM">Isle of Man</option>
                        <option value="IL">Israel</option>
                        <option value="IT">Italy</option>
                        <option value="JM">Jamaica</option>
                        <option value="JP">Japan</option>
                        <option value="JE">Jersey</option>
                        <option value="JO">Jordan</option>
                        <option value="KZ">Kazakhstan</option>
                        <option value="KE">Kenya</option>
                        <option value="KI">Kiribati</option>
                        <option value="KP">Korea (Democratic People's Republic of)</option>
                        <option value="KR">Korea (Republic of)</option>
                        <option value="XK">Kosovo</option>
                        <option value="KW">Kuwait</option>
                        <option value="KG">Kyrgyzstan</option>
                        <option value="LA">Lao People's Democratic Republic</option>
                        <option value="LV">Latvia</option>
                        <option value="LB">Lebanon</option>
                        <option value="LS">Lesotho</option>
                        <option value="LR">Liberia</option>
                        <option value="LY">Libya</option>
                        <option value="LI">Liechtenstein</option>
                        <option value="LT">Lithuania</option>
                        <option value="LU">Luxembourg</option>
                        <option value="MO">Macao</option>
                        <option value="MG">Madagascar</option>
                        <option value="MW">Malawi</option>
                        <option value="MY">Malaysia</option>
                        <option value="MV">Maldives</option>
                        <option value="ML">Mali</option>
                        <option value="MT">Malta</option>
                        <option value="MH">Marshall Islands</option>
                        <option value="MQ">Martinique</option>
                        <option value="MR">Mauritania</option>
                        <option value="MU">Mauritius</option>
                        <option value="YT">Mayotte</option>
                        <option value="MX">Mexico</option>
                        <option value="FM">Micronesia (Federated States of)</option>
                        <option value="MD">Moldova (Republic of)</option>
                        <option value="MC">Monaco</option>
                        <option value="MN">Mongolia</option>
                        <option value="ME">Montenegro</option>
                        <option value="MS">Montserrat</option>
                        <option value="MA">Morocco</option>
                        <option value="MZ">Mozambique</option>
                        <option value="MM">Myanmar</option>
                        <option value="NA">Namibia</option>
                        <option value="NR">Nauru</option>
                        <option value="NP">Nepal</option>
                        <option value="NL">Netherlands</option>
                        <option value="NC">New Caledonia</option>
                        <option value="NZ">New Zealand</option>
                        <option value="NI">Nicaragua</option>
                        <option value="NE">Niger</option>
                        <option value="NG">Nigeria</option>
                        <option value="NU">Niue</option>
                        <option value="NF">Norfolk Island</option>
                        <option value="MK">North Macedonia (Republic of)</option>
                        <option value="MP">Northern Mariana Islands</option>
                        <option value="NO">Norway</option>
                        <option value="OM">Oman</option>
                        <option value="PK">Pakistan</option>
                        <option value="PW">Palau</option>
                        <option value="PS">Palestine (State of)</option>
                        <option value="PA">Panama</option>
                        <option value="PG">Papua New Guinea</option>
                        <option value="PY">Paraguay</option>
                        <option value="PE">Peru</option>
                        <option value="PH">Philippines</option>
                        <option value="PN">Pitcairn</option>
                        <option value="PL">Poland</option>
                        <option value="PT">Portugal</option>
                        <option value="PR">Puerto Rico</option>
                        <option value="QA">Qatar</option>
                        <option value="RO">Romania</option>
                        <option value="RU">Russian Federation</option>
                        <option value="RW">Rwanda</option>
                        <option value="RE">Réunion</option>
                        <option value="BL">Saint Barthélemy</option>
                        <option value="SH">Saint Helena, Ascension and Tristan da Cunha</option>
                        <option value="KN">Saint Kitts and Nevis</option>
                        <option value="LC">Saint Lucia</option>
                        <option value="MF">Saint Martin (French part)</option>
                        <option value="PM">Saint Pierre and Miquelon</option>
                        <option value="VC">Saint Vincent and the Grenadines</option>
                        <option value="WS">Samoa</option><option value="SM">San Marino</option>
                        <option value="ST">Sao Tome and Principe</option>
                        <option value="SA">Saudi Arabia</option>
                        <option value="SN">Senegal</option>
                        <option value="RS">Serbia</option>
                        <option value="SC">Seychelles</option>
                        <option value="SL">Sierra Leone</option>
                        <option value="SG">Singapore</option>
                        <option value="SX">Sint Maarten (Dutch part)</option>
                        <option value="SK">Slovakia</option>
                        <option value="SI">Slovenia</option>
                        <option value="SB">Solomon Islands</option>
                        <option value="SO">Somalia</option>
                        <option value="ZA">South Africa</option>
                        <option value="GS">South Georgia and the South Sandwich Islands</option>
                        <option value="SS">South Sudan</option>
                        <option value="ES">Spain</option>
                        <option value="LK">Sri Lanka</option>
                        <option value="SD">Sudan</option>
                        <option value="SR">Suriname</option>
                        <option value="SJ">Svalbard and Jan Mayen</option>
                        <option value="SE">Sweden</option>
                        <option value="CH">Switzerland</option>
                        <option value="SY">Syrian Arab Republic</option>
                        <option value="TW">Taiwan, Province of China</option>
                        <option value="TJ">Tajikistan</option>
                        <option value="TZ">Tanzania (United Republic of)</option>
                        <option value="TH">Thailand</option>
                        <option value="TL">Timor-Leste</option>
                        <option value="TG">Togo</option>
                        <option value="TK">Tokelau</option>
                        <option value="TO">Tonga</option>
                        <option value="TT">Trinidad and Tobago</option>
                        <option value="TN">Tunisia</option>
                        <option value="TR">Turkey</option>
                        <option value="TM">Turkmenistan</option>
                        <option value="TC">Turks and Caicos Islands</option>
                        <option value="TV">Tuvalu</option>
                        <option value="UG">Uganda</option>
                        <option value="UA">Ukraine</option>
                        <option value="AE">United Arab Emirates</option>
                        <option value="GB">United Kingdom (GB and NI)</option>
                        <option value="UM">United States Minor Outlying Islands</option>
                        <option value="US">United States of America</option>
                        <option value="UY">Uruguay</option>
                        <option value="UZ">Uzbekistan</option>
                        <option value="VU">Vanuatu</option>
                        <option value="VA">Vatican City State</option>
                        <option value="VE">Venezuela (Bolivarian Republic of)</option>
                        <option value="VN">Vietnam</option>
                        <option value="VG">Virgin Islands (British)</option>
                        <option value="VI">Virgin Islands (U.S.)</option>
                        <option value="WF">Wallis and Futuna</option>
                        <option value="EH">Western Sahara</option>
                        <option value="YE">Yemen</option>
                        <option value="ZM">Zambia</option>
                        <option value="ZW">Zimbabwe</option>
                        <option value="AX">Åland Islands</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div v-if="formState.hubType === 'location'" class="pt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
                  <div class="sm:col-span-2">
                    <label for="address-one" class="block text-sm font-medium text-gray-700">Address Line 1</label>
                    <div class="mt-1">
                      <input v-model="formState.addressOne" type="text" name="address-one" id="address-one" autocomplete="off" class="block w-full rounded-md border-gray-300 py-3 px-4 shadow-sm focus:border-blue focus:ring-blue" />
                    </div>
                  </div>
                  <div class="sm:col-span-2">
                    <label for="address-two" class="block text-sm font-medium text-gray-700">Address Line 2</label>
                    <div class="mt-1">
                      <input v-model="formState.addressTwo" type="text" name="address-two" id="address-two" autocomplete="off" class="block w-full rounded-md border-gray-300 py-3 px-4 shadow-sm focus:border-blue focus:ring-blue" />
                    </div>
                  </div>
                  <div class="sm:col-span-2">
                    <label for="town-city" class="block text-sm font-medium text-gray-700">Town/City</label>
                    <div class="mt-1">
                      <input v-model="formState.townCity" type="text" name="town-city" id="town-city" autocomplete="off" class="block w-full rounded-md border-gray-300 py-3 px-4 shadow-sm focus:border-blue focus:ring-blue" />
                    </div>
                  </div>
                  <div class="sm:col-span-2">
                    <label for="county" class="block text-sm font-medium text-gray-700">County/State</label>
                    <div class="mt-1">
                      <input v-model="formState.county"  type="text" name="county" id="county" autocomplete="off" class="block w-full rounded-md border-gray-300 py-3 px-4 shadow-sm focus:border-blue focus:ring-blue" />
                    </div>
                  </div>
                  <div class="sm:col-span-2">
                    <label for="post-code" class="block text-sm font-medium text-gray-700">Post/Zip Code</label>
                    <div class="mt-1">
                      <input v-model="formState.postCode" type="text" name="post-code" id="post-code" autocomplete="off" class="block w-full rounded-md border-gray-300 py-3 px-4 shadow-sm focus:border-blue focus:ring-blue" />
                    </div>
                  </div>
                </div>
                <div class="pt-10 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
                  <div class="sm:col-span-2">
                    <ErrorMessage :formError="formState.error" class="mb-6"></ErrorMessage>
                    <SuccessMessage :successMessage="formState.result" class="mb-6"></SuccessMessage>
                    <button type="button" @click.prevent="submitForm" :disabled="formState.executing || formState.result !== ''" class="disabled:opacity-50 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-blue px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-blue focus:ring-offset-2">Register</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>

      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8" id="info-panel">
        <h2 class="mt-10 lg:mt-15 font-display text-2xl font-bold tracking-tighter text-gray-900 sm:text-4xl">
          Frequently Asked Questions
        </h2>

        <div class="mt-6 space-y-6 font-display text-xl tracking-tight text-gray-600">
          <strong>What my role as a Hub Host? </strong><br />
            The hosts main role is gathering Nazarites, communicating with them, and helping everyone to participate not just spectate. Encouraging active participation is important during the praying sessions, you are encouraged to direct prayer on local issues when appropriate. <br /><br />
            There is no expectation of pastoring, mentoring, or solving people's problems - this should be done within the local church structure and if issues or safeguarding concerns arrives these must be referred to delegates pastors. However, please be alert to any safeguarding issues and refer them to your churches safeguarding team. <br /><br />
            Remind Nazarites they should try to attend each event, as their accountability partners will depend on them.
        </div>

        <div class="mt-6 space-y-6 font-display text-xl tracking-tight text-gray-600">
          <strong>How do I create accountability groups? </strong><br />
          On the first evening Nazarites will be asked to create their own accountability groups. They must all be same sex, a minimum of 3 and max of 5.  Its normally easiest to let people self-organise into these groups, as doing it with those you already know can be helpful. If you are doing it online, you will need know how to create break out groups for each group.
        </div>

        <div class="mt-6 space-y-6 font-display text-xl tracking-tight text-gray-600">
          <strong>Can minors join? </strong><br />
          We recommend that minors only join with their guardian or their guardian's explicit permission. Please remember that Prayer Strom has no control over who attends.
        </div>

        <div class="mt-6 space-y-6 font-display text-xl tracking-tight text-gray-600">
          <strong>Can married couples do it by themselves? </strong><br />
          If they cannot or prefer not to join another hub, a married couple can create a hub just for themselves and be each other accountability partners.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { mapGetters } from 'vuex'
import { ensureLoggedInOrRedirect } from '@/lib/util'
import responseHelper from '@/helpers/response.helper'
import store from '@/store'
import ErrorMessage from '@/components/ErrorMessage.vue'
import SuccessMessage from '@/components/SuccessMessage.vue'
import { useHubs } from '@/composables/useHubs'

export default defineComponent({
  components: {
    ErrorMessage,
    SuccessMessage
  },
  setup () {
    const route = useRoute()
    const router = useRouter()

    const { postHubCreate } = useHubs()

    const formState = reactive({
      hub: undefined,
      sessionUrl: '',
      firstName: store.getters.getFirstName,
      lastName: store.getters.getLastName,
      email: store.getters.getEmail,
      hubType: '',
      title: '',
      description: '',
      tel: '',
      country: '',
      addressOne: '',
      addressTwo: '',
      townCity: '',
      county: '',
      postCode: '',
      executing: false,
      error: responseHelper.noError,
      result: ''
    })

    const loginForHubs = () => {
      ensureLoggedInOrRedirect(router, route)
    }

    const submitForm = () => {
      postHubCreate(formState)
        .then((result) => {
          if (result && result.hubId) {
            formState.result = 'You have registered a Nazarite Hub! Further details will arive on email soon.'
          }
        })
        .catch(() => { })
    }

    return {
      formState,
      loginForHubs,
      submitForm
    }
  },
  computed: {
    ...mapGetters([
      'isLoggedIn'
    ])
  }
})
</script>
