<template>
 <div class="bg-white">
    <div class="relative pb-10">
      <div>
        <div class="relative">
          <div class="absolute inset-0">
            <!-- <video id="bg-vid" autoplay loop muted playsinline>
              <source src="/video/home-bg.mp4" type="video/mp4" />
            </video> -->
            <img class="w-full h-full object-cover" src="https://pslive.blob.core.windows.net/images/event/81be38c0-b197-11ef-8575-bf85d8bfee0f-event.jpg" alt="" />
            <div class="absolute inset-0" aria-hidden="true" style="background-image: linear-gradient(to left bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8))" />
          </div>
          <div class="relative max-w-7xl mx-auto md:p-6">
            <div class="max-w-3xl mx-auto">
              <div>
                <img src="https://pslive.blob.core.windows.net/images/event/81be38c0-b197-11ef-8575-bf85d8bfee0f-event.jpg" alt="" class="md:rounded-xl">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="relative z-50 flex-none border-b py-2">
        <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 flex flex-wrap items-center justify-center lg:flex-nowrap">
          <div class="flex space-x-2 md:space-x-4">
            <router-link to="/event/117/nazarite-school" class="inline-flex justify-center rounded-xl bg-blue p-2 md:p-4 text-sm md:text-base font-semibold text-white hover:bg-blue-dark focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-500" href="#">
              <span>Attend Manchester Nazarite School</span>
            </router-link>
          </div>
        </div>
      </div>

      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8" id="info-panel">
        <!-- <img class="mb-20 rounded-xl" src="/img/prayer-storm-conference-2025-1920x1080.jpg" /> -->

        <h1 class="mt-10 lg:mt-15 font-display text-4xl font-bold tracking-tighter text-gray-900 sm:text-6xl">
          Nazarite Hubs</h1>
        <div class="mt-6 space-y-6 font-display text-2xl tracking-tight text-gray-600">
          <p>Join a global community of Nazarites preparing the way for the kingdom of heaven to come on earth. Exclusively for those outside Greater Manchester, we're excited to launch Nazarite Hubs. Because community, accountability, and praying for each other are essential to the Nazarites journey, we only live-stream to registered hubs - local gatherings of Nazarites, a married couple, or online groups.</p>
        </div>
        <div class="mt-6 space-y-6 font-display text-2xl tracking-tight text-gray-600">
          <p>The hubs are open-access and not vetted, so we cannot endorse individual hubs, their leaders or those attending. Rather, we're providing a way for people to host local Nazarite communities and join us online.</p>
        </div>

      </div>

      <section aria-labelledby="schedule-title" class="py-10">
        <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">

          <div class="space-y-6 font-display text-lg tracking-tight text-gray-600 italic">
            <p>PLEASE NOTE: Only one host is required to register per hub.</p>
          </div>
          <div class="grid grid-cols-1 sm:grid-cols-2 sm:gap-x-4 lg:gap-x-8">
            <section>
              <ol role="list" class="rounded-xl mt-10 space-y-4 bg-white/60 p-4 md:p-8 text-center shadow-xl backdrop-blur border">
                <li>
                  <h3 class="text-xl font-semibold tracking-tight text-gray-900">
                    Host a Nazarite Hub
                  </h3>
                </li>
                <li>
                  <p class="mt-1 font-mono text-sm text-slate-500">For more information about hosting and to register a hub.</p>
                </li>
                <li>
                  <router-link :to="'/nazarite-hubs/host'">
                    <button class="inline-flex justify-center rounded-xl bg-blue p-2 md:p-4 text-sm md:text-base font-semibold text-white hover:bg-blue-dark focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-500">Host</button>
                  </router-link>
                </li>
              </ol>
            </section>
            <section>
              <ol role="list" class="rounded-xl mt-10 space-y-4 bg-white/60 p-4 md:p-8 text-center shadow-xl backdrop-blur border">
                <li>
                  <h3 class="text-xl font-semibold tracking-tight text-gray-900">
                    Join a Nazarite Hub
                  </h3>
                </li>
                <li>
                  <p class="mt-1 font-mono text-sm text-slate-500">To join a hub hosted by someone else, outside of Manchester.</p>
                </li>
                <li>
                  <router-link :to="'/nazarite-hubs/search'">
                    <button class="inline-flex justify-center rounded-xl bg-blue p-2 md:p-4 text-sm md:text-base font-semibold text-white hover:bg-blue-dark focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-500">Join</button>
                  </router-link>
                </li>
              </ol>
            </section>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script setup>
</script>
