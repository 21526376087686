<template>
 <div class="bg-white">
    <div class="relative pb-10">
      <div>
        <div class="relative">
          <div class="absolute inset-0">
            <!-- <video id="bg-vid" autoplay loop muted playsinline>
              <source src="/video/home-bg.mp4" type="video/mp4" />
            </video> -->
            <img class="w-full h-full object-cover" src="https://pslive.blob.core.windows.net/images/event/81be38c0-b197-11ef-8575-bf85d8bfee0f-event.jpg" alt="" />
            <div class="absolute inset-0" aria-hidden="true" style="background-image: linear-gradient(to left bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8))" />
          </div>
          <div class="relative max-w-7xl mx-auto md:p-6">
            <div class="max-w-3xl mx-auto">
              <div>
                <img src="https://pslive.blob.core.windows.net/images/event/81be38c0-b197-11ef-8575-bf85d8bfee0f-event.jpg" alt="" class="md:rounded-xl">
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8" id="info-panel">
        <!-- <img class="mb-20 rounded-xl" src="/img/prayer-storm-conference-2025-1920x1080.jpg" /> -->

        <h1 class="mt-10 lg:mt-15 font-display text-4xl font-bold tracking-tighter text-gray-900 sm:text-6xl">
          Join Nazarite Hub: <span v-if="formState.hub"> {{ formState.hub.title }}</span></h1>
        <div class="mt-6 space-y-6 font-display text-2xl tracking-tight text-gray-600">
          <p>Remember, anyone can create a hub so Prayer Storm cannot vet hub leaders. Hubs are designed for existing communities, so we recommend only joining a hub with someone who you already know and never go to an unknown person's house alone.</p>
        </div>
      </div>

      <section aria-labelledby="schedule-title" class="py-4">
        <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div class="overflow-hidden bg-white py-8 px-4 sm:px-6 lg:px-8">
            <div class="relative mx-auto max-w-2xl">
              <svg class="absolute left-full translate-x-1/2 transform" width="404" height="404" fill="none" viewBox="0 0 404 404" aria-hidden="true">
                <defs>
                  <pattern id="85737c0e-0916-41d7-917f-596dc7edfa27" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                    <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width="404" height="404" fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)" />
              </svg>
              <svg class="absolute right-full bottom-0 -translate-x-1/2 transform" width="404" height="404" fill="none" viewBox="0 0 404 404" aria-hidden="true">
                <defs>
                  <pattern id="85737c0e-0916-41d7-917f-596dc7edfa27" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                    <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width="404" height="404" fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)" />
              </svg>
              <div v-if="formState.initialLoad" class="">
                <p>Loading...</p>
              </div>
              <ErrorMessage :formError="formState.error" class="mb-6"></ErrorMessage>

              <div v-if="formState.hub" class="">
                <div class="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
                  <div class="pt-4 text-center sm:col-span-2">
                    <h2 class="text-xl font-bold tracking-tight text-2xl">Hub Details</h2>
                    <p class="mt-1 text-gray-500 italic">Please check the details of this hub and the host before you join.</p>
                  </div>

                  <div class="sm:col-span-2">
                    <label for="hubType" class="block text-sm text-gray-700">Name</label>
                    <div class="text-xl font-bold">
                      <p>
                       {{ formState.hub.title }}
                      </p>
                    </div>
                  </div>

                  <div class="sm:col-span-2">
                    <label for="hubType" class="block text-sm text-gray-700">Description</label>
                    <div class="text-xl font-bold">
                      <p>
                       {{ formState.hub.description }}
                      </p>
                    </div>
                  </div>

                  <div class="sm:col-span-2">
                    <label for="hubType" class="block text-sm text-gray-700">Host</label>
                    <div class="text-xl font-bold">
                      <p>
                       {{formState.hub.firstName}} {{formState.hub.lastName}}
                      </p>
                    </div>
                  </div>

                  <div class="sm:col-span-2">
                    <label for="hubType" class="block text-sm text-gray-700">Hub Type</label>
                    <div class="text-xl font-bold">
                      <p>
                        <span v-if="formState.hub.online">Online Hub - meeting via video conferencing and live stream</span>
                        <span v-else>Location Hub - gathering to meet at the location below</span>
                      </p>
                    </div>
                  </div>

                  <div class="sm:col-span-2">
                    <label for="hubType" class="block text-sm text-gray-700">Location</label>
                    <div class="text-xl font-bold">
                      <p>
                        <span v-if="!formState.hub.online">
                          {{formState.hub.addressOne}}<br />
                          {{formState.hub.addressTwo}}<br />
                          {{formState.hub.townCity}}<br />
                          {{formState.hub.county}}<br />
                          {{formState.hub.postCode}}<br />
                        </span>
                       {{formState.hub.countryName}}
                      </p>
                    </div>
                  </div>

                  <div class="pt-10 text-center sm:col-span-2">
                    <h2 class="text-xl font-bold tracking-tight text-gray-900 sm:text-2xl">Your Details</h2>
                    <p class="mt-1 text-gray-500 italic">These details will update against your Prayer Storm account, and used by the Hub Host to make contact with you.</p>
                  </div>
                  <div>
                    <label for="first-name" class="block text-sm font-medium text-gray-700">First Name</label>
                    <div class="mt-1">
                      <input v-model="formState.firstName" type="text" name="first-name" id="first-name" autocomplete="given-name" class="block w-full rounded-md border-gray-300 py-3 px-4 shadow-sm focus:border-blue focus:ring-blue" />
                    </div>
                  </div>
                  <div>
                    <label for="last-name" class="block text-sm font-medium text-gray-700">Last Name</label>
                    <div class="mt-1">
                      <input v-model="formState.lastName" type="text" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-gray-300 py-3 px-4 shadow-sm focus:border-blue focus:ring-blue" />
                    </div>
                  </div>
                  <div class="sm:col-span-2">
                    <label for="email" class="block text-sm font-medium text-gray-700">Email</label>
                    <div class="mt-1">
                      <input v-model="formState.email" disableds id="email" name="email" type="email" autocomplete="email" class="block w-full rounded-md border-gray-200 text-gray-500 py-3 px-4 shadow-sm focus:border-blue focus:ring-blue bg-gray-50 shadow-none" />
                    </div>
                  </div>
                  <div class="sm:col-span-2">
                    <label for="tel" class="block text-sm font-medium text-gray-700">Telephone</label>
                    <div class="mt-1">
                      <input v-model="formState.tel" id="tel" name="tel" type="text" autocomplete="tel" class="block w-full rounded-md border-gray-300 py-3 px-4 shadow-sm focus:border-blue focus:ring-blue" />
                    </div>
                  </div>
                </div>
                <div class="pt-10 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
                  <div class="sm:col-span-2">
                    <ErrorMessage :formError="formState.errorJoin" class="mb-6"></ErrorMessage>
                    <SuccessMessage :successMessage="formState.resultJoin" class="mb-6"></SuccessMessage>
                    <button type="button" @click.prevent="submitForm" :disabled="formState.executingJoin || formState.resultJoin !== ''" class="disabled:opacity-50 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-blue px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-blue focus:ring-offset-2">Join</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import store from '@/store'
import responseHelper from '@/helpers/response.helper'
import ErrorMessage from '@/components/ErrorMessage.vue'
import SuccessMessage from '@/components/SuccessMessage.vue'
import { useHubs } from '@/composables/useHubs'

export default defineComponent({
  components: {
    ErrorMessage,
    SuccessMessage
  },

  setup () {
    const route = useRoute()
    const { postHubSearchByCode, postHubJoinByCode } = useHubs()

    const formState = reactive({
      hub: undefined,
      firstName: store.getters.getFirstName,
      lastName: store.getters.getLastName,
      email: store.getters.getEmail,
      tel: '',
      hubCode: route.params.code,
      initialLoad: true,
      executing: false,
      executingJoin: false,
      error: responseHelper.noError,
      errorJoin: responseHelper.noError,
      result: '',
      resultJoin: ''
    })

    onMounted(() => {
      postHubSearchByCode(formState)
        .then((result) => {
          if (result && result.hubId) {
            formState.hub = result
          }
        })
        .catch(() => { })
        .finally(() => {
          formState.initialLoad = false
        })
    })

    const submitForm = async () => {
      postHubJoinByCode(formState)
        .then((result) => {
          if (result && result.hubId) {
            formState.resultJoin = 'You have successfully joined the hub, the hub leader will be notified and make contact soon.'
          }
        })
        .catch(() => { })
    }

    return {
      formState,
      submitForm
    }
  }
})
</script>
