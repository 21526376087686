<template>
    <div class="relative pb-10">
      <div>
        <div class="relative">
          <div class="absolute inset-0">
            <!-- <video id="bg-vid" autoplay loop muted playsinline>
              <source src="/video/home-bg.mp4" type="video/mp4" />
            </video> -->
            <img class="w-full h-full object-cover" src="https://pslive.blob.core.windows.net/images/event/32e1f460-7c33-11ef-9f15-97a779fb81f1-event.jpg" alt="" />
            <div class="absolute inset-0" aria-hidden="true" style="background-image: linear-gradient(to left bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8))" />
          </div>
          <div class="relative max-w-7xl mx-auto md:p-6">
            <div class="max-w-3xl mx-auto">
              <div>
                <img src="https://pslive.blob.core.windows.net/images/event/32e1f460-7c33-11ef-9f15-97a779fb81f1-event.jpg" alt="" class="md:rounded-xl">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="relative z-50 flex-none border-b py-2">
        <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 flex flex-wrap items-center justify-center lg:flex-nowrap">
          <div class="flex space-x-2 md:space-x-4">
            <a @click.prevent="scrollToId('info-panel')" class="inline-flex justify-center rounded-xl bg-transparent p-2 md:p-4 text-sm md:text-base font-semibold text-gray-900 hover:bg-gray-200 focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500" href="#">
              Info
            </a>
            <a @click.prevent="scrollToId('faqs-panel')" class="inline-flex justify-center rounded-xl bg-transparent p-2 md:p-4 text-sm md:text-base font-semibold text-gray-900 hover:bg-gray-200 focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500" href="#">
              FAQs
            </a>
            <a class="inline-flex justify-center rounded-xl bg-blue p-2 md:p-4 text-sm md:text-base font-semibold text-white hover:bg-blue-dark focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-500" href="https://docs.google.com/forms/d/e/1FAIpQLSdL_1BjRYj4VhwRMXOY5-yq7U_XIaHDU6Pd5OXGIeln4f7G4Q/viewform" target="_blank">
              <span class="hidden md:inline">Waiting List</span>
              <span class="md:hidden">Waiting List</span>
            </a>
          </div>
        </div>
      </div>

      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8" id="info-panel">
        <!-- <img class="mb-20 rounded-xl" src="/img/prayer-storm-conference-2025-1920x1080.jpg" /> -->

        <h1 class="mt-10 lg:mt-15 font-display text-4xl font-bold tracking-tighter text-gray-900 sm:text-6xl">
          Prayer Storm Netherlands Conference</h1>
        <div class="mt-6 space-y-6 font-display text-2xl tracking-tight text-gray-600">
          <p>The team are excited for you to join us for our first Prayer Storm Netherlands Conference! Taking place at Safe Haven Church, Amsterdam, join us for 4 sessions across Friday evening and Saturday. We hope to see you there! The event is free, donations will be taken on the night.</p>
        </div>
        <dl class="mt-10 grid md:grid-cols-2 gap-x-10 gap-y-4 md:auto-cols-auto md:grid-flow-col md:grid-cols-none md:justify-start text-left">
          <div>
            <dt class="font-mono text-sm text-gray-600">When</dt>
            <dd class="mt-0.5 text-2xl font-semibold tracking-tight text-gray-900">31 Jan - 1 Feb, 2025</dd>
          </div>
          <div>
            <dt class="font-mono text-sm text-gray-600">Venue</dt>
            <dd class="mt-0.5 text-2xl font-semibold tracking-tight text-gray-900">Safe Haven Church Amsterdam, H.J.E. Wenckebachweg 117, 1096 AM, Amsterdam, Netherlands
            </dd>
          </div>
          <div>
            <dt class="font-mono text-sm text-gray-600">Tickets</dt>
            <dd class="mt-0.5 text-2xl font-semibold tracking-tight text-gray-900">
              This event is now at maximum capacity <br />
              <a class="text-blue hover:underline" href="https://docs.google.com/forms/d/e/1FAIpQLSdL_1BjRYj4VhwRMXOY5-yq7U_XIaHDU6Pd5OXGIeln4f7G4Q/viewform" target="_blank">Join the waiting list</a>
            </dd>
          </div>
        </dl>
      </div>
    </div>
    <section id="schedule" aria-labelledby="schedule-title" class="py-10">
      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <h2 class="font-display text-4xl font-bold tracking-tighter text-gray-900 sm:text-5xl">
          Schedule
        </h2>
        <div class="grid grid-cols-1 sm:grid-cols-2 sm:gap-x-4 lg:gap-x-8">
          <section>
            <ol role="list" class="rounded-xl mt-10 space-y-4 bg-white/60 p-4 md:p-8 text-center shadow-xl backdrop-blur border">
              <li>
                <h3 class="text-xl font-semibold tracking-tight text-gray-900">
                  31 January 2025
                </h3>
              </li>
              <li>
                <h4 class="text-lg font-semibold tracking-tight text-gray-900">Evening Session</h4>
                <p class="mt-1 font-mono text-sm text-slate-500">19:00 CET</p>
              </li>
            </ol>
          </section>
          <section>
            <ol role="list" class="rounded-xl mt-10 space-y-4 bg-white/60 p-4 md:p-8 text-center shadow-xl backdrop-blur border">
              <li>
                <h3 class="text-xl font-semibold tracking-tight text-gray-900">
                  1 February 2025
                </h3>
              </li>
              <li>
                <h4 class="text-lg font-semibold tracking-tight text-gray-900">Morning Session</h4>
                <p class="mt-1 font-mono text-sm text-slate-500">10:00 CET</p>
              </li>
              <li>
                <h4 class="text-lg font-semibold tracking-tight text-gray-900">Afternoon Session</h4>
                <p class="mt-1 font-mono text-sm text-slate-500">14:00 CET</p>
              </li>
              <li>
                <h4 class="text-lg font-semibold tracking-tight text-gray-900">Evening Session</h4>
                <p class="mt-1 font-mono text-sm text-slate-500">18:00 CET</p>
              </li>
            </ol>
          </section>
        </div>
      </div>
    </section>

    <ContentFAQsNetherlands></ContentFAQsNetherlands>

</template>

<script setup>
import ContentFAQsNetherlands from '@/components/ContentFAQsNetherlands.vue'

const scrollToId = (elementId) => {
  setTimeout(() => {
    const scrollTo = document.getElementById(elementId)
    scrollTo.scrollIntoView({ behavior: 'smooth' })
  }, 200)
}
</script>
